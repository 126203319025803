<script>
import Modal from '@maison/components/common/Modal.vue';
import modalMixin from '@phoenix/mixins/modalMixin';

export default {
  components: {
    Modal,
  },

  mixins: [modalMixin],
};
</script>

<template>
  <modal :id="id" :title="$t('CountrySelector.title')" large data-cy="country-selector-modal">
    <template #content>
      <slot />
    </template>
  </modal>
</template>
